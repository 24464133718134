import Vue from 'vue'
import Vuex from 'vuex'
import {ColeccionLanding, ColeccionLandingImagenes} from '@/FirebaseConfig'
import { onSnapshot } from 'firebase/firestore'
Vue.use(Vuex)
export default {
    namespaced:true,
    state:{
      contenido: {},
      cargando: true,
      lista_de_imagenes: [],
    
    },
    actions:{
      obtener:({state})=>{
        if (!state.cargando) return 
        state.cargando = true
        onSnapshot(ColeccionLanding, doc=>{
          if(doc.exists) state.contenido = doc.data()
          state.cargando = false
        })
        onSnapshot(ColeccionLandingImagenes, doc=>{
          if(doc.exists) state.lista_de_imagenes = doc.data()?.lista
        })
      },
    },
    getters:{
      contenido_especifico: ({contenido}) => ({pagina}) =>  {
        return contenido ? contenido[pagina] : {} 
      },
      elementos: ({contenido}) => elemento => contenido ? contenido[elemento] : [],
      contenido: ({contenido}) => contenido,

      imágenes: state => state.lista_de_imagenes

    }

}
